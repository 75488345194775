import React from "react";
import clsx from "clsx";
import { CHECK_MARK_WHITE_SVG, CLOSE_WHITE_SVG } from "../../../constants/svgConstants";

export const PromocodeInput = ({
  inputType = "text",
  placeholderValue,
  errorMessage = "",
  inputRef,
  value = "",
  onClick = () => null,
  onChange = () => null,
  onBlur = () => null,
  classNameWrapper,
  disabled = false,
  hasAddIcon = true,
}) => {
  return (
    <div className={clsx(classNameWrapper)}>
      <input
        {...(inputRef && { ref: inputRef })}
        className={clsx("sign-up-section__input promocode__input", {
          "sign-up-section__input_error": !!errorMessage,
        })}
        // size={18}
        disabled={disabled}
        type={inputType}
        placeholder={placeholderValue}
        // {...(value && { value })}
        {...(!inputRef && { value })}
        onKeyDown={(e) => e.key === "Enter" && onClick()}
        {...(onChange && { onChange })}
        {...(onBlur && { onBlur })}
      />
      {/* <span className="promocode__add-button" onClick={onClick}>
        {PLUS_WHITE_SVG}
      </span> */}
      {hasAddIcon && (
        <span className="promocode__add-button" onClick={onClick}>
          {CHECK_MARK_WHITE_SVG}
        </span>
      )}
      <span className="promocode__close-button" onClick={() => onClick({ isClose: true })}>
        {CLOSE_WHITE_SVG}
      </span>

      {/* {errorMessage && (
        <div className="sign-up-section__input-error-message">
          <p>{errorMessage}</p>
        </div>
      )} */}
    </div>
  );
};
